import PropTypes from "prop-types";
import styles from "./pricingCard.module.css";
import Subscription from "../../features/subscription/Subscription";

export default function PricingCard({
  title,
  price,
  features,
  buttonText,
  buttonAction,
  moreInfoAction,
  isHighlighted,
  isProTariff,
  isRecurringPrice,
}) {
  const isStarterPlan = title.toLowerCase() === "starter";

  const currencySymbolMatch = price.match(/[^0-9,.]/);
  const currencySymbol = currencySymbolMatch ? currencySymbolMatch[0] : "$";
  const numericPrice = parseFloat(price.replace(/[^0-9.]/g, "")) || 0;

  const handleMoreInfoClick = (e) => {
    e.preventDefault();
    moreInfoAction();
  };

  return (
    <div className={`${styles.pricingCard} ${isHighlighted ? styles.highlighted : ""}`}>
      <div className={styles.pricingHeader}>
        <h3>{title}</h3>
        <div className={styles.divider} />

        <p className={styles.cardPrice}>
          {isStarterPlan ? "Try it free" : price}
          {!isStarterPlan && <span>/month</span>}
        </p>

        <p className={styles.billingInfo}>
          {isStarterPlan
            ? "This plan is available for 2 weeks"
            : `${currencySymbol}${(numericPrice * 12).toLocaleString()} billed annually`}
          <br />
          {isStarterPlan ? "(No payment required)" : !isStarterPlan && isRecurringPrice && "(25% discount)"}
        </p>
      </div>

      <Subscription isProTariff={isProTariff} isRecurringPrice={isRecurringPrice} />

      <a href="#" className={styles.moreInfo} onClick={handleMoreInfoClick}>
        More information
      </a>

      <div className={styles.divider} />

      <ul className={styles.featuresList}>
        {features.map((feature) => (
          <li key={feature}>
            <img src="/circle-check-lilla.webp" alt="check icon" />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
}

PricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonAction: PropTypes.func.isRequired,
  moreInfoAction: PropTypes.func.isRequired,
  isHighlighted: PropTypes.bool,
  isProTariff: PropTypes.bool.isRequired,
  isRecurringPrice: PropTypes.bool.isRequired,
};

PricingCard.defaultProps = {
  isHighlighted: false,
  price: "N/A",
};
